import { createHttpEndpoint } from '../../utils'

export const getConfiguration = createHttpEndpoint<AlgoliaConfiguration>({
  method: 'GET',
  operationId: 'recommendationConfiguration',
  path: '/bm/recommendation/configuration',
})

export type AlgoliaConfiguration = {
  apiKey: string
  indexName: string
}
